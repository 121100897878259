import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5, faCss3Alt, faJs, faReact, faNodeJs, faPython, faJava } from '@fortawesome/free-brands-svg-icons';
import { faChevronLeft, faChevronRight, faCopy, faHome } from '@fortawesome/free-solid-svg-icons';
import tutorialsData from '../data/tutorials';
import '../style/TutorialBox.css';

const TutorialBox = () => {
    const [searchQuery] = useState('');
    const [selectedTutorials, setSelectedTutorials] = useState([]);
    const [currentTutorialIndex, setCurrentTutorialIndex] = useState(0);
    const [selectedSection, setSelectedSection] = useState(null);
    const [isTutorialSelected, setIsTutorialSelected] = useState(false);
    const [visibleTechIndex, setVisibleTechIndex] = useState(0);

    const technologies = [
        { name: 'HTML', icon: faHtml5, color: '#E34F26' },
        { name: 'CSS', icon: faCss3Alt, color: '#1572B6' },
        { name: 'JavaScript', icon: faJs, color: '#F7DF1E' },
        { name: 'PHP', icon: faJs, color: '#777BB3' },
        { name: 'React', icon: faReact, color: '#61DAFB' },
        { name: 'Vue.js', icon: faJs, color: '#42b883' },
        { name: 'Angular', icon: faJs, color: '#DD1B16' },
        { name: 'Node.js', icon: faNodeJs, color: '#68A063' },
        { name: 'Python', icon: faPython, color: '#306998' },
        { name: 'Ruby', icon: faJs, color: '#701516' },
        { name: 'TypeScript', icon: faJs, color: '#007ACC' },
        { name: 'React.js', icon: faReact, color: '#61DAFB' },
        { name: 'Next.js', icon: faJs, color: '#000000' },
        { name: 'Nuxt.js', icon: faJs, color: '#00C58E' },
        { name: 'Tailwind CSS', icon: faJs, color: '#38BDF8' },
        { name: 'Bootstrap', icon: faJs, color: '#563D7C' },
        { name: 'Sass', icon: faJs, color: '#CC6699' },
        { name: 'Express.js', icon: faNodeJs, color: '#000000' },
        { name: 'Go', icon: faJs, color: '#00ADD8' },
        { name: 'Django', icon: faPython, color: '#092E20' },
        { name: 'Flask', icon: faPython, color: '#000000' },
        { name: 'Ruby on Rails', icon: faJs, color: '#CC0000' },
        { name: 'Spring Boot', icon: faJava, color: '#6DB33F' },
        { name: 'Rust', icon: faJava, color: '#000000' },
        { name: 'Kotlin', icon: faJava, color: '#A97BFF' },
        { name: 'GraphQL', icon: faJs, color: '#E10098' },
        { name: 'REST APIs', icon: faJs, color: '#7E7E7E' },
        { name: 'WebSockets', icon: faJs, color: '#7E7E7E' },
        { name: 'Docker', icon: faJs, color: '#2496ED' },
        { name: 'Kubernetes', icon: faJs, color: '#326CE5' },
        { name: 'Microservices', icon: faJs, color: '#F1C232' }
    ];

    const filteredTechnologies = technologies.filter(tech => tech.name.toLowerCase().includes(searchQuery.toLowerCase()));

    const handleTechnologyClick = (techName) => {
        const filteredTutorials = tutorialsData.filter(tutorial => tutorial.title.toLowerCase().includes(techName.toLowerCase()));
        setSelectedTutorials(filteredTutorials);
        setCurrentTutorialIndex(0);
        setSelectedSection(null);
        setIsTutorialSelected(true);
    };

    const handleNextTech = () => visibleTechIndex < filteredTechnologies.length - 5 && setVisibleTechIndex(visibleTechIndex + 1);
    const handlePrevTech = () => visibleTechIndex > 0 && setVisibleTechIndex(visibleTechIndex - 1);

    const handleSectionClick = (index) => {
        setSelectedSection(index); // Set selected section index
    };

    const getSections = () => {
        const htmlContent = selectedTutorials[currentTutorialIndex]?.htmlContent;
        return htmlContent ? htmlContent : [];
    };

    const copyCodeToClipboard = (code) => {
        navigator.clipboard.writeText(code).then(() => {
            alert('Code copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy code: ', err);
        });
    };

    const handleBackToHome = () => {
        setIsTutorialSelected(false);
        setSelectedSection(null);
        setVisibleTechIndex(0);
    };
    return (
        <div className="container-fluid" style={{ paddingBottom: 0 }}>
            {/* Primary Navbar */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top mr-5" style={{ borderBottom: '2px solid #ddd', zIndex: 1000 }}>
                <div className="container-fluid">
                    <button className="btn btn" style={{ zIndex: 1000, marginLeft: '34px', }} onClick={handleBackToHome}>
                        <FontAwesomeIcon icon={faHome} className="me-2" />
                        Home
                    </button>
                </div>
            </nav>

            {/* Secondary Navbar */}
            <nav
                className="navbar navbar-expand-lg navbar-light bg-light"
                style={{
                    borderBottom: '2px solid #ddd',
                    marginTop: '10px',
                    zIndex: 999,
                    position: 'sticky',
                    top: '60px',
                    padding: '10px 20px',
                }}
            >
                <div className="container-fluid">
                    {/* Previous Button */}
                    <button
                        className="btn mx-1"
                        onClick={handlePrevTech}
                        disabled={visibleTechIndex === 0}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>

                    {/* Technologies Carousel */}
                    <div
                        className="d-flex overflow-hidden mx-3"
                        style={{
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '15px',
                        }}
                    >
                        {filteredTechnologies.slice(visibleTechIndex, visibleTechIndex + filteredTechnologies.length).map((tech, index) => (
                            <button
                                key={tech.name}
                                className="btn btn-light text-center mx-1"
                                onClick={() => handleTechnologyClick(tech.name)}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '10px',
                                    border: '1px solid #ddd',
                                    borderRadius: '10px',
                                    minWidth: '100px',
                                    gap: '8px',
                                    transition: 'box-shadow 0.3s ease, transform 0.3s ease',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={tech.icon}
                                    style={{
                                        fontSize: '20px',
                                        color: tech.color,
                                    }}
                                />
                                <span style={{ fontSize: '14px', color: '#555' }}>{tech.name}</span>
                            </button>
                        ))}
                    </div>

                    {/* Next Button */}
                    <button
                        className="btn mx-1"
                        onClick={handleNextTech}
                        disabled={visibleTechIndex >= filteredTechnologies.length - 5}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40px',
                            height: '40px',
                        }}
                    >
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            </nav>




            {/* Main content */}
            <div className="row flex-nowrap tutorial-row mt-5">
                {/* Sidebar with Sections */}
                {isTutorialSelected && (
                    <div className="col-3 sidebar">
                        <ul className="list-group">
                            {getSections().map((section, index) => (
                                <li key={index} className={`list-group-item ${selectedSection === index ? 'active' : ''}`} onClick={() => handleSectionClick(index)}>
                                    {section.sectionTitle}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}

                <div className="col content">
                    {isTutorialSelected ? (
                        selectedTutorials.length > 0 ? (
                            <div className="tutorial-card">
                                <h2>{selectedTutorials[currentTutorialIndex].title}</h2>
                                <p>{selectedTutorials[currentTutorialIndex].description}</p>
                                {selectedSection === null ? (
                                    <div className="section-content">
                                        {getSections().map((section, index) => (
                                            <div key={index} className="code-card">
                                                <div className="card-header">
                                                    <button className="btn btn-sm btn-outline-secondary" onClick={() => copyCodeToClipboard(section.content)} style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                        <FontAwesomeIcon icon={faCopy} />
                                                    </button>
                                                </div>
                                                <div className="card-body">
                                                    <pre><code dangerouslySetInnerHTML={{ __html: section.content }} /></pre>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div className="code-card">
                                        <div className="card-header">
                                            <button className="btn btn-sm btn-outline-secondary" onClick={() => copyCodeToClipboard(getSections()[selectedSection].content)} style={{ position: 'absolute', top: '5px', right: '5px' }}>
                                                <FontAwesomeIcon icon={faCopy} />
                                            </button>
                                        </div>
                                        <div className="card-body">
                                            <pre><code dangerouslySetInnerHTML={{ __html: getSections()[selectedSection].content }} /></pre>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p className="text-center">Select a technology to view the tutorials.</p>
                        )
                    ) : (
                        <div
                            className="welcome-message "
                            style={{ marginTop: '8px' }}
                        >
                            <h2 className="primary" style={{ marginLeft: '50px' }}>Welcome to the Tutorial Box!</h2>
                            <p style={{ marginLeft: '50px' }}>
                                Explore a wide range of tutorials on various technologies. Select a
                                technology from the navigation above to get started.
                            </p>
                            {/* Cards Container */}
                            <div
                                className="cards-container-wrapper"
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                    gap: '15px',
                                    marginTop: '30px',
                                    overflowX: 'hidden',
                                    padding: '0 15px',
                                    boxSizing: 'border-box',
                                }}
                            >
                                {/* Array for Card Content */}
                                {technologies.map((cardName, index) => (
                                    <div
                                        key={index}
                                        className="card"
                                        style={{
                                            width: '190px',
                                            height: '140px',
                                            borderRadius: '8px',
                                            textAlign: 'center',
                                            position: 'relative',
                                            overflow: 'hidden',
                                            cursor: 'pointer',
                                            backgroundColor: 'rgb(255, 246, 227)',
                                        }}
                                        onClick={() => handleTechnologyClick(cardName.name)}
                                    >
                                        {/* Gradient Border Effect */}
                                        <div
                                            style={{
                                                position: 'absolute',
                                                inset: '0',
                                                background: 'linear-gradient(45deg, #ff6b6b, #fbc531, #4cd137, #00a8ff, #9c88ff)',
                                                zIndex: '0',
                                                borderRadius: '8px',
                                                padding: '3px', // Space for border effect
                                                boxSizing: 'border-box',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    backgroundColor: 'rgb(255, 246, 227)', // Card background
                                                    borderRadius: '6px', // Inner content rounded corners
                                                    boxShadow: '0px 0px 15px 1px rgba(0, 0, 0, 0.1)',
                                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'relative',
                                                    zIndex: '1', // Ensure content is above the gradient border
                                                }}
                                                className="card-inner"
                                            >
                                                <h5 style={{ fontSize: '14px', color: 'blue' }}>{cardName.name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                    )}
                </div>
            </div>
        </div>
    );
};

export default TutorialBox;
