import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserTie, faClock, faUser } from '@fortawesome/free-solid-svg-icons';

const courseData = [
  { id: 1, title: 'Frontend Development Course for Beginners', price: '₹8000', instructor: 'Ram Sir', duration: '8 Months', students: '35 Students' },
  { id: 2, title: 'Backend Development Course for Beginners', price: '₹8000', instructor: 'Ravi Sir', duration: '8 Months', students: '14 Students' },
  { id: 3, title: 'Fullstack Development Course for Beginners', price: '₹12000', instructor: 'Ravi Sir', duration: '8 Months', students: '23 Students' },
  { id: 4, title: 'MEAN Full Stack Development Course', price: '₹12000', instructor: 'Ram Sir', duration: '8 Months', students: '41 Students' },
  { id: 5, title: 'MERN Full Stack Development Course', price: '₹12000', instructor: 'Ram Sir', duration: '8 Months', students: '55 Students' },
  { id: 6, title: 'DSA Course for Beginners', price: '₹12000', instructor: 'Nishu Sir', duration: '12 Months', students: '36 Students' },
  { id: 7, title: 'Java Programming Course', price: '₹12000', instructor: 'Divya Mam', duration: '8 Months', students: '35 Students' },
  { id: 8, title: 'Graphic Designing Course', price: '₹12000', instructor: 'Ram Sir', duration: '8 Months', students: '13 Students' }
];

const Courses = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleApplyClick = (course) => {
    setSelectedCourse(course);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedCourse(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with course details:", selectedCourse);
    setShowPopup(false);
  };

  return (
    <section id='courses' className="container-xxl py-5 mb-5">
      <header className="mb-5 mt-3 border-bottom text-center wow fadeInUp" data-wow-delay="0.1s">
        <h1 style={{ color: '#1976d2' }}>
          <span>&#8621;</span>Explore Our Courses<span>&#8621;</span>
        </h1>
      </header>
      <div className="row gy-4">
        {courseData.map(course => (
          <article className="col-md-6 col-lg-4 col-xl-3" key={course.id}>
            <div
              className={`border border card d-flex flex-column h-100`}
              style={{
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                borderRadius: '10px',
                cursor: 'pointer',
              }}
            >
              <div className="card-body d-flex flex-column justify-content-between text-muted" style={{ height: '100%' }}>
                <h2 className="card-title mb-3 text-muted" style={{ fontSize: '1.1rem', fontWeight: '600' }}>{course.title}</h2>
                <p className="mb-2 text-muted" style={{ fontSize: '1rem', fontWeight: '500' }}><strong>Price:</strong> {course.price}</p>
                <div className="d-flex justify-content-between text-muted mb-3">
                  <small>
                    <FontAwesomeIcon
                      icon={faUserTie}
                      className="me-2"
                      style={{ color: '#007bff' }} // Blue color for instructor
                    />
                    {course.instructor}
                  </small>

                  <small>
                    <FontAwesomeIcon
                      icon={faClock}
                      className="me-2"
                      style={{ color: '#f39c12' }} // Yellow color for duration
                    />
                    {course.duration}
                  </small>

                  <small>
                    <FontAwesomeIcon
                      icon={faUser}
                      className="me-2"
                      style={{ color: '#28a745' }} // Green color for students
                    />
                    {course.students}
                  </small>

                </div>
                <footer className="text-center mt-auto">
                  <button
                    className="btn btn-outline-primary"
                    style={{
                      borderRadius: '5px',
                      fontWeight: '500',
                    }}
                    onClick={() => handleApplyClick(course)}
                  >
                    Apply Now
                  </button>
                </footer>
              </div>
            </div>
          </article>
        ))}
      </div>

      {showPopup && selectedCourse && (
        <div className="popup-overlay" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <div className="popup-content mt-2 mb-2" style={{
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '10px',
            width: '400px',
            position: 'relative',
          }}>
            <h3 className="mb-1 mt-2">Apply for {selectedCourse.title}</h3>
            <form onSubmit={handleSubmit}>
              <div className="mb-1">
                <label className="form-label">Course Title</label>
                <input type="text" className="form-control" value={selectedCourse.title} readOnly />
              </div>
              <div className="mb-1">
                <label className="form-label">Price</label>
                <input type="text" className="form-control" value={selectedCourse.price} readOnly />
              </div>
              <div className="mb-1">
                <label className="form-label">Instructor</label>
                <input type="text" className="form-control" value={selectedCourse.instructor} readOnly />
              </div>
              <div className="mb-1">
                <label className="form-label">Duration</label>
                <input type="text" className="form-control" value={selectedCourse.duration} readOnly />
              </div>
              <div className="mb-1">
                <label className="form-label">Email</label>
                <input type="email" id="email" name="email" className="form-control" required />
              </div>
              <div className="mb-1">
                <label className="form-label" for="mobile">Mobile Number</label>
                <input type="tel" className="form-control" pattern="[0-9]{10}" maxLength="10" required />
              </div>
              <div className='row mb-1'>
                <div className='col-md-6'>
                  <button type="submit" className="btn btn-primary mt-1 w-100">Submit</button>
                </div>
                <div className='col-md-6'>
                  <button
                    className="btn btn-secondary mt-1 w-100"
                    onClick={handleClosePopup}
                  >
                    Close
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </section>
  );
};

export default Courses;
