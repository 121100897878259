import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import navBarlogo from '../assets/img/internstepslogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FaHome, FaServicestack, FaListAlt, FaChalkboardTeacher, FaUsers, FaStar, FaPhoneAlt, FaLock } from 'react-icons/fa';
import '../style/Navbar.css';
import AuthModals from './AuthModals';

const Navbar = ({ darkMode }) => {
  const [showModal, setShowModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState('Home');
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const openAuthModal = () => setShowModal(true);
  const closeAuthModal = () => setShowModal(false);

  const textClass = darkMode ? 'text-white' : 'text-primary';
  const bgClass = darkMode ? 'bg-dark' : 'bg-light';

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 50);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <nav className={`navbar navbar-expand-lg ${bgClass} ${isScrolled ? 'transparent' : ''} shadow-sm sticky-top`}>
        <div className="container d-flex justify-content-between align-items-center">
          <Link to="/" className="navbar-brand d-flex align-items-center">
            <h5 className={`m-0 ${textClass} logo-text`}>Intern <img src={navBarlogo} alt="Logo" className="logo-img" /> Steps</h5>
          </Link>
          <button className="navbar-toggler" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} className={textClass} />
          </button>
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}>
            <ul className="navbar-nav ms-auto">
              {['Home', 'Service', 'Categories', 'Courses', 'Team', 'Testimonial', 'Contact'].map((item) => (
                <li key={item} className="nav-item mx-2">
                  <a
                    href={`#${item.toLowerCase()}`}
                    className={`nav-link ${textClass} ${activeLink === item ? 'active' : ''}`}
                    onClick={() => setActiveLink(item)}
                  >
                    <span className="me-2">
                      {item === 'Home' && <FaHome />}
                      {item === 'Service' && <FaServicestack />}
                      {item === 'Categories' && <FaListAlt />}
                      {item === 'Courses' && <FaChalkboardTeacher />}
                      {item === 'Team' && <FaUsers />}
                      {item === 'Testimonial' && <FaStar />}
                      {item === 'Contact' && <FaPhoneAlt />}
                    </span>
                    {item}
                  </a>
                </li>
              ))}
              <li className="nav-item" style={{ marginTop: '-8px' }}>
                {/* Trigger modal on click for Login */}
                <a href="#" className="nav-link" onClick={openAuthModal}>
                  <button className="btn btn-primary d-flex align-items-center">
                    <FaLock className="me-2" style={{ fontSize: '14px', color: 'white' }} />
                    Login
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {showModal && <AuthModals onClose={closeAuthModal} />}
    </>
  );
};

export default Navbar;
