import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhoneAlt, faEnvelopeOpen, faClock } from "@fortawesome/free-solid-svg-icons";
import { IoMdHappy } from "react-icons/io"; // Importing the IoMdHappy icon

const Contact = () => {
  // State to track if the form is submitted
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // For displaying error message
  const [successMessage, setSuccessMessage] = useState(""); // For displaying success message

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission
    setIsSubmitted(true); // Set the form as submitted

    const form = e.target;
    const formData = new FormData(form);

    fetch(form.action, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (response.ok) { // Check if the response status is OK (200-299)
          setSuccessMessage("Your message has been submitted!"); // Set success message
          setErrorMessage(""); // Clear any previous error messages
        } else {
          throw new Error("There was an error submitting the form. Please try again.");
        }
      })
      .catch((error) => {
        setErrorMessage(error.message); // Set error message
        setSuccessMessage(""); // Clear any previous success messages
      });
  };

  return (
    <section id="contact" className="container-xxl d-flex justify-content-center align-items-center py-5" style={{ minHeight: '0vh' }}>
      <div className="container">
        <header className="mb-5 mt-3 border-bottom text-center wow fadeInUp" data-wow-delay="0.1s">
          <h1 style={{ color: '#1976d2' }}>
            <span>&#8621;</span>Contact For Any Query<span>&#8621;</span>
          </h1>
        </header>
        <div className="row g-4">
          <article className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div className="border border rounded p-4 h-100">
              <h5>Get In Touch</h5>
              <div className="d-flex align-items-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Office</h5>
                  <p className="mb-0">Bulandshahr, Uttar Pradesh, Pin:- 203203</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faPhoneAlt} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Mobile</h5>
                  <p className="mb-0">+91 9027587069</p>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faEnvelopeOpen} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Email</h5>
                  <p className="mb-0">internsteps4u@gmail.com</p>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div
                  className="d-flex align-items-center justify-content-center flex-shrink-0 bg-primary"
                  style={{ width: "50px", height: "50px" }}
                >
                  <FontAwesomeIcon icon={faClock} className="text-white" />
                </div>
                <div className="ms-3">
                  <h5 className="text-primary">Office Hours</h5>
                  <p className="mb-0">Mon - Fri: 9:00 AM to 6:00 PM</p>
                </div>
              </div>
            </div>
          </article>

          {/* Google Maps section */}
          <article className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div className="border border rounded p-4 h-100">
              <div className="embed-responsive">
                <iframe
                  className="position-relative rounded w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3511.294076723119!2d77.78405207534767!3d28.34995747582017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ca5e0d60f69a3%3A0x8fffc2fe9c860ab6!2sSRS%20(education%20of%20computer)!5e0!3m2!1sen!2sin!4v1721733516231!5m2!1sen!2sin"
                  frameBorder="0"
                  style={{ minHeight: "300px", border: "0" }}
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex="0"
                  title="Google Maps Location"
                ></iframe>
              </div>
            </div>
          </article>

          {/* Contact Form */}
          {isSubmitted ? (
            <article className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="border border rounded p-4 h-100">
                <h5 className="text-center">
                  Thank you for reaching out to us!
                </h5>
                <p className="text-center">We will get back to you soon.</p>
                <div className="text-center">
                  <IoMdHappy style={{ color: 'green', fontSize: '200px' }} /> {/* Larger icon, centered */}
                </div>
              </div>
            </article>
          ) : (
            <article className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="border border rounded p-4 h-100">
                {successMessage && <div className="alert alert-success">{successMessage}</div>}
                {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                <form onSubmit={handleFormSubmit} action="https://docs.google.com/forms/d/e/1FAIpQLSf4LrWOVl_N7JAqk9OX1sJ0knZpgA6zVW7nKqww-cYHZ7twLQ/formResponse" method="POST">
                  <fieldset>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <input type="text" className="form-control" name="entry.2005620554" placeholder="Name" required />
                      </div>
                      <div className="col-md-6">
                        <input type="email" className="form-control" name="entry.1045781291" placeholder="Email" required />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          className="form-control"
                          name="entry.1166974658"
                          placeholder="Mobile"
                          required
                          pattern="[0-9]{10}"
                          maxLength="10"
                        />
                      </div>
                      <div className="col-md-6">
                        <input type="text" className="form-control" name="entry.1065046570" placeholder="Address" required />
                      </div>
                      <div className="col-12">
                        <input type="text" className="form-control" name="entry.848348626" placeholder="Subject" required />
                      </div>
                      <div className="col-12">
                        <textarea className="form-control" name="entry.839337160" placeholder="Leave a message here" required></textarea>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100 py-3" type="submit">Send Message</button>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </div>
            </article>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
