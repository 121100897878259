import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaHtml5, FaCss3Alt, FaJs, FaReact, FaAngular, FaVuejs, FaNodeJs, FaPython, FaJava, FaDocker, FaDatabase, FaCogs } from 'react-icons/fa';

const InterviewPreparation = () => {
    const colors = [
        'bg-primary text-white',
        'bg-success text-white',
        'bg-warning text-muted',
        'bg-info text-white',
        'bg-danger text-white',
        'bg-secondary text-white',
        'bg-dark text-white',
    ];

    const sections = [
        {
            title: 'HTML',
            description: 'Prepare for your HTML interviews with topics such as semantic HTML, forms, accessibility, and more.',
            scheduleLink: 'https://example.com/schedule/html',
            mockLink: 'https://example.com/mock/html',
            time: '2 Hours',
            price: '$1',
            platform: 'Zoom',
            icon: <FaHtml5 />
        },
        {
            title: 'CSS',
            description: 'Get ready for CSS interviews by reviewing topics like Flexbox, Grid, responsive design, and CSS preprocessors.',
            scheduleLink: 'https://example.com/schedule/css',
            mockLink: 'https://example.com/mock/css',
            time: '3 Hours',
            price: '$1',
            platform: 'Microsoft Teams',
            icon: <FaCss3Alt />
        },
        {
            title: 'JavaScript',
            description: 'Brush up on JavaScript fundamentals, ES6 features, asynchronous programming, and common algorithms.',
            scheduleLink: 'https://example.com/schedule/js',
            mockLink: 'https://example.com/mock/js',
            time: '4 Hours',
            price: '$1',
            platform: 'Google Meet',
            icon: <FaJs />
        },
        {
            title: 'React',
            description: 'Prepare for React interviews by studying component lifecycle, hooks, state management, and testing React components.',
            scheduleLink: 'https://example.com/schedule/react',
            mockLink: 'https://example.com/mock/react',
            time: '3.5 Hours',
            price: '$1',
            platform: 'Zoom',
            icon: <FaReact />
        },
        {
            title: 'Angular',
            description: 'Review Angular concepts including components, services, directives, RxJS, and Angular CLI for interviews.',
            scheduleLink: 'https://example.com/schedule/angular',
            mockLink: 'https://example.com/mock/angular',
            time: '3 Hours',
            price: '$1',
            platform: 'Microsoft Teams',
            icon: <FaAngular />
        },
        {
            title: 'Vue.js',
            description: 'Get ready for Vue.js interviews with topics like Vue Router, Vuex, and component-based architecture.',
            scheduleLink: 'https://example.com/schedule/vue',
            mockLink: 'https://example.com/mock/vue',
            time: '2.5 Hours',
            price: '$1',
            platform: 'Google Meet',
            icon: <FaVuejs />
        },
        {
            title: 'Node.js',
            description: 'Prepare for Node.js interviews by reviewing topics like Express.js, RESTful APIs, middleware, and event-driven architecture.',
            scheduleLink: 'https://example.com/schedule/nodejs',
            mockLink: 'https://example.com/mock/nodejs',
            time: '4 Hours',
            price: '$1',
            platform: 'Zoom',
            icon: <FaNodeJs />
        },
        {
            title: 'Python',
            description: 'Study Python fundamentals, web frameworks like Django and Flask, and data structures for Python interviews.',
            scheduleLink: 'https://example.com/schedule/python',
            mockLink: 'https://example.com/mock/python',
            time: '4 Hours',
            price: '$1',
            platform: 'Microsoft Teams',
            icon: <FaPython />
        },
        {
            title: 'Java',
            description: 'Review Java concepts including OOP principles, data structures, and frameworks like Spring Boot.',
            scheduleLink: 'https://example.com/schedule/java',
            mockLink: 'https://example.com/mock/java',
            time: '4 Hours',
            price: '$1',
            platform: 'Google Meet',
            icon: <FaJava />
        },
        {
            title: 'DevOps',
            description: 'Get ready for DevOps interviews by reviewing CI/CD, Docker, Kubernetes, and cloud infrastructure.',
            scheduleLink: 'https://example.com/schedule/devops',
            mockLink: 'https://example.com/mock/devops',
            time: '5 Hours',
            price: '$1',
            platform: 'Google Meet',
            icon: <FaDocker />
        },
        {
            title: 'Data Structures & Algorithms',
            description: 'Strengthen your understanding of data structures, algorithms, and problem-solving techniques.',
            scheduleLink: 'https://example.com/schedule/dsa',
            mockLink: 'https://example.com/mock/dsa',
            time: '5 Hours',
            price: '$1',
            platform: 'Zoom',
            icon: <FaCogs />
        },
        {
            title: 'SQL & Databases',
            description: 'Prepare for database interviews with topics like SQL queries, normalization, and database design.',
            scheduleLink: 'https://example.com/schedule/sql',
            mockLink: 'https://example.com/mock/sql',
            time: '3.5 Hours',
            price: '$1',
            platform: 'Microsoft Teams',
            icon: <FaDatabase />
        }
    ];

    return (
        <div className="container min-vh-100 py-4">
            <h1 className="mb-4 text-center" style={{
                fontSize: '3rem',
                fontWeight: '700',
                color: '#2575fc',
                textShadow: '2px 2px #6a11cb',
                borderBottom: '3px solid #2575fc',
                paddingBottom: '10px'
            }}>
                Interview Preparation
            </h1>

            <p className="mb-5 text-center text-muted">Here you will find resources and guidance to help you prepare for your interviews.</p>
            <div className="row">
                {sections.map((section, index) => (
                    <div className="col-md-6 col-lg-4 mb-4" key={index}>
                        <div className={`card h-100 ${colors[index % colors.length]}`}>
                            <div className="card-body">
                                <h5 className="card-title d-flex align-items-center">
                                    {section.icon}
                                    <span className="ms-2">{section.title}</span>
                                </h5>
                                <p className="card-text">{section.description}</p>
                                <p className='mb-1'><strong>Time:</strong> {section.time}</p>
                                <p className='mb-1'><strong>Price:</strong> {section.price}</p>
                                <p className='mb-1'><strong>Platform:</strong> {section.platform}</p>
                            </div>
                            <div className="card-footer d-flex justify-content-between">
                                <a href={section.scheduleLink} className="btn btn-light btn-sm me-2">Schedule Interview</a>
                                <a href={section.mockLink} className="btn btn-dark btn-sm">Mock Interview</a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default InterviewPreparation;
