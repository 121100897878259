import React from 'react';

const AboutInternship = () => {
  return (
    <section id="AboutInternship" className="container mt-5 mb-5">
      
      {/* Paid Internship Section */}
      <section id="paidInternship" className="mb-5 p-4" style={{ backgroundColor: '#f4f8fb', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h1 style={{ color: '#007bff' }}>Paid Internship</h1>
        </header>
        <p>Gain hands-on experience with financial compensation and professional networking. Many internships lead to full-time opportunities!</p>
        
        <h4>Benefits:</h4>
        <ul>
          <li>Earn while learning</li>
          <li>Industry experience</li>
          <li>Networking and mentorship</li>
        </ul>
      </section>

      {/* Unpaid Internship Section */}
      <section id="unpaidInternship" className="mb-5 p-4" style={{ backgroundColor: '#e9f7fa', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h1 style={{ color: '#17a2b8' }}>Unpaid Internship</h1>
        </header>
        <p>Gain valuable experience and academic credit while networking with professionals in your field.</p>

        <h4>Benefits:</h4>
        <ul>
          <li>Learning and exposure</li>
          <li>Academic credit</li>
          <li>Networking opportunities</li>
        </ul>
      </section>

      {/* Installment Internship Section */}
      <section id="installmentInternship" className="mb-5 p-4" style={{ backgroundColor: '#f7f6f2', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h1 style={{ color: '#28a745' }}>Installment Internship</h1>
        </header>
        <p>Flexible, segmented internships to balance work, education, and personal life.</p>

        <h4>Benefits:</h4>
        <ul>
          <li>Structured learning in phases</li>
          <li>Flexible scheduling</li>
          <li>Continuous feedback</li>
        </ul>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="mb-5 p-4" style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h2>What Our Interns Say</h2>
        </header>
        <blockquote>"This internship helped me land my dream job!" - Mohit</blockquote>
        <blockquote>"Great learning experience and mentorship!" - Rahul</blockquote>
      </section>

      {/* Contact Section */}
      <section id="contactSection" className="mb-5 p-4" style={{ backgroundColor: '#f1f1f1', borderRadius: '10px' }}>
        <header className="text-center mb-4">
          <h2>Get in Touch</h2>
        </header>
        <form>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name</label>
            <input type="text" className="form-control" id="name" placeholder="Your Name" />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" placeholder="Your Email" />
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      </section>
    </section>
  );
};

export default AboutInternship;
