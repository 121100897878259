import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChalkboardTeacher, faLaptop, faProjectDiagram,
    faUsers, faCertificate, faClock, faBullhorn,
    faSuitcase, faExternalLink
} from "@fortawesome/free-solid-svg-icons";

const services = [
    { icon: faChalkboardTeacher, title: "Skilled Instructors", description: "Learn from industry experts with years of experience and in-depth knowledge in their respective fields." }, 
    { icon: faLaptop, title: "Online Classes", description: "Attend live classes and interactive sessions from the comfort of your home, anywhere in the world." }, 
    { icon: faProjectDiagram, title: "Hands-On Projects", description: "Gain practical experience by working on real-world projects that reinforce your learning and skills." }, 
    { icon: faUsers, title: "Collaborative Environment", description: "Work with peers and mentors in a collaborative online learning environment." }, 
    { icon: faCertificate, title: "Certifications", description: "Earn certifications to showcase your skills and knowledge." }, 
    { icon: faClock, title: "Self-Paced Learning", description: "Take control of your learning schedule with self-paced courses and resources.", rightIcon: faExternalLink, rightIconLink: "/tutorial-box" }, 
    { icon: faBullhorn, title: "Interview Preparation", description: "Prepare for interviews in any technology with expert guidance and mock interviews.", rightIcon: faExternalLink, rightIconLink: "/interview-preparation" }, 
    { icon: faSuitcase, title: "Internship Opportunities", description: "Explore various internship opportunities to gain practical experience in your chosen field.", rightIcon: faExternalLink, rightIconLink: "/about-internship" }
];

const Service = () => (
    <section id="service" className="container-xxl py-5">
        <header className="mb-5 mt-4 border-bottom text-center wow fadeInUp" data-wow-delay="0.1s">
            <h1 style={{ color: '#1976d2' }}><span>&#8621;</span> Service <span>&#8621;</span></h1>
            <h2 className='text-muted'><span>&#8621;</span> Learning | Internship | Training <span>&#8621;</span></h2>
        </header>
        <div className="row gx-4 gy-4">
            {services.map((service, index) => (
                <ServiceCard key={index} {...service} />
            ))}
        </div>
    </section>
);

const ServiceCard = ({ icon, title, description, rightIcon, rightIconLink }) => (
    <article className="col-lg-3 col-sm-6 d-flex align-items-stretch ">
        <div className="border border service-item text-center pt-3 h-100" style={{ ...styles.card }}>
            <FontAwesomeIcon
                icon={icon}
                className="fa-3x mb-4"
                style={{ color: '#1976d2' }} // Set icon color to a specific value (light blue here)
            />
            <h5 className="mb-3 text-muted" >{title}</h5> {/* Text color for title */}
            <p className="text-muted">{description}</p>
            {rightIcon && (
                <a href={rightIconLink} className="mt-3" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={rightIcon} style={{ color: '#1976d2' }} /> {/* Set icon color for the right icon */}
                </a>
            )}
        </div>
    </article>
);

const styles = {
    card: {
        transition: 'transform 1s ease-in-out, box-shadow 0.2s ease-in-out',
        borderRadius: '10px',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
        backgroundColor: '#fff', // White background for the card
    },
};

export default Service;
